<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">健康資訊表</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Client' }">客戶管理</router-link>
						</li>
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'ClientDetail', params: { id: clientData.id } }">{{
								clientData.name
							}}</router-link>
						</li>
						<li class="breadcrumb-item active">健康資訊表</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="mb-4">
			<Vueform ref="searchForm$">
				<SelectElement
					name="lesson"
					placeholder="選擇課程"
					type="select"
					:native="false"
					:search="true"
					:items="healthForm.lessonList"
					:default="0"
					@select="
						(option) => {
							healthForm.getData(option);
						}
					"
					columns="6"
					:can-clear="false"
					:can-deselect="false"
				/>
			</Vueform>
		</div>

		<div v-for="(item, index) in healthForm.data" :key="item.id" :id="`template-${item.id}`">
			<div class="card mb-4">
				<div class="card-body">
					<FillBuilder
						:ref="
							(el) => {
								healthFormRefs$[index] = el;
							}
						"
						:formDisabled="true"
					/>
				</div>
			</div>
		</div>

		<div class="d-flex justify-content-center align-items-center mt-5">
			<router-link :to="{ name: 'ClientDetail', params: { id: clientData.id } }" class="btn btn-secondary"
				>返回</router-link
			>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { apiGetSpecialTemplate } from "@/assets/js/api.js";
import { formatDatetime } from "@/assets/js/common.js";
import { useIndexDB } from "@/assets/js/indexDB.js";
import { ref, reactive, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import FillBuilder from "@/components/FillBuilder";

export default {
	name: "HealthReport",
	components: {
		FillBuilder,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		let clientIDB;

		// 客戶資料
		const clientData = reactive({
			id: 0,
			name: "",
		});

		//健康資訊表資料
		const healthFormRefs$ = ref([]);
		const healthForm = reactive({
			list: [],
			data: [],
			lessonList: [],
			getList() {
				setPageLoading(true);

				apiGetSpecialTemplate({
					client_id: clientData.id,
					template_type: 4,
				})
					.then((response) => {
						setPageLoading(false);
						if (response.data.status == "0") {
							healthForm.list = response.data.data;

							healthForm.lessonList = response.data.data.map((item) => {
								return {
									value: item.class_id,
									label: `${formatDatetime(item.class_date)} ${item.lesson_name}`,
								};
							});
						} else {
							Swal.fire({
								icon: "warning",
								text: response.data.message,
							}).then(() => {
								router.push({ name: "Client" });
							});
						}
					})
					.catch((error) => {
						setPageLoading(false);
						Swal.fire({
							icon: "error",
							text: "CODE ERROR",
						});

						console.error(error);
					});
			},
			getData(id) {
				healthForm.data = healthForm.list.filter((item) => item.class_id == id);

				let setForm = async (formRef$, item) => {
					let { title, content, template_id, template_content } = item;
					template_content = template_content.length == 0 ? {} : JSON.parse(template_content);
					content = content.length == 0 ? {} : JSON.parse(content);

					// 《個人健康評估結果》排序在最前面
					let orderedTemplate = [];
					let orderedContent = {};

					orderedTemplate[0] = {
						...template_content[0],
						originIndex: 0,
					};

					for (let i = 66; i < template_content.length; i++) {
						orderedTemplate.push({
							...template_content[i],
							originIndex: i,
						});
					}

					template_content.splice(66, template_content.length - 1);

					for (let i = 1; i < template_content.length; i++) {
						orderedTemplate.push({
							...template_content[i],
							originIndex: i,
						});
					}

					for (let key in content) {
						const newIndex = orderedTemplate.findIndex((item) => item.originIndex == key);
						orderedContent[newIndex] = content[key];
					}

					await formRef$.setFormData({
						templateID: template_id,
						title,
						questions: orderedTemplate,
					});
					await formRef$.setFillData(orderedContent);
				};

				nextTick(() => {
					let nullIndex = [];
					for (let i = 0; i < healthFormRefs$.value.length; i++) {
						if (healthFormRefs$.value[i] == null) {
							nullIndex.push(i);
							continue;
						} else {
							setForm(healthFormRefs$.value[i], healthForm.data[i]);
						}
					}

					for (let i = 0; i < nullIndex.length; i++) {
						healthFormRefs$.value.splice(nullIndex[i], 1);
					}
				});
			},
		});

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		onMounted(async () => {
			clientData.id = route.params.id;
			clientIDB = await useIndexDB("clients", "client");
			clientData.name = (await clientIDB.get(parseInt(clientData.id))).name;

			healthForm.getList();
		});

		return {
			clientData,
			healthFormRefs$,
			healthForm,
		};
	},
	beforeRouteEnter(to, from, next) {
		if (!to.params.id) {
			next({ name: "Client" });
		} else {
			next();
		}
	},
};
</script>

<style lang="scss" scoped></style>
